import React from 'react';
// import './CityWidget.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { Color, Controller, Strip } from "../Controller";
import { ColorResult, HuePicker, RGBColor } from "react-color";


interface PickerState {
	color: Color
	intensity?: number
	turnedOff: boolean
}

interface PickerProps {
	color: Color;
	title: string;
	intensity?: number;
	turnedOff: boolean;
	controller: Controller;
	stripIndex: number;
	stripName: string;
	online: boolean;
	version: string
	onColorChange: (color: Color, intensity: number | undefined, stripName: string) => Promise<void>;
}

export class Picker extends React.Component<PickerProps, PickerState> {
	constructor(props: PickerProps) {
		super(props);
		this.state = {color: this.props.color, turnedOff: this.props.turnedOff, intensity: this.props.intensity};
	}

	componentDidMount() {
		if (this.props.intensity !== undefined) {
			this.toggleButtons(this.props.intensity);
			this.setState({color: this.props.color})
		}
	}

	toggleButtons = (intensity: number) => {
		for (const level of [10, 40, 70, 100]) {
			const button = document.getElementById(`${this.props.title}-${this.props.stripIndex}-intensity-${level}`);

			if (!button)
				continue

			if (level == intensity) {
				button.classList.add("toggled")
				button.classList.remove("non-toggled")
			} else {
				button.classList.remove("toggled")
				button.classList.add("non-toggled")
			}
		}

	}

	changeColor = (color: ColorResult) => {
		this.setState({color: new Color(color.hex)});
	}

	changeIntensity = (intensity: number) => {
		this.toggleButtons(intensity);
		this.setState({intensity, turnedOff: intensity === 0});
		this.props.onColorChange(
			this.state.color,
			intensity,
			this.props.stripName
		);

	}

	turnOff = () => {
		this.changeIntensity(this.state.turnedOff ? 100 : 0);
	}

	render() {
		return (
			<div className={"picker fadeIn"} key={this.props.stripIndex}>
				<div className={"header"}>
				<span>{this.props.stripName}</span>
				<button
					style={{color: this.state.turnedOff ? "#000000" : this.state.color.asHEX()}}
					className={"hue-picker-turnoff"}
					onClick={this.turnOff}
				>
					<FontAwesomeIcon icon={"plug" as unknown as IconDefinition} size={"1x"}/>
				</button>
				</div>
				<div className={"hue-picker-container"} id={"container"}>
					<div className={"hues"}>
						<HuePicker
							color={this.state.color}
							onChange={this.changeColor}
							onChangeComplete={(color) => this.props.onColorChange(
								new Color(color.rgb),
								this.state.intensity,
								this.props.stripName
							)}
						/>
					</div>
					<div className={"intensity"}>
						<button id={`${this.props.title}-${this.props.stripIndex}-intensity-10`} onClick={() => this.changeIntensity(10)}>10%</button>
						<button id={`${this.props.title}-${this.props.stripIndex}-intensity-40`} onClick={() => this.changeIntensity(40)}>40%</button>
						<button id={`${this.props.title}-${this.props.stripIndex}-intensity-70`} onClick={() => this.changeIntensity(70)}>70%</button>
						<button id={`${this.props.title}-${this.props.stripIndex}-intensity-100`} onClick={() => this.changeIntensity(100)}>100%</button>
					</div>
					<div className={"picker-metadata"}	>
						<FontAwesomeIcon icon={faCircle} color={this.props.online ? "green" : "red"} size={"xs"}/><span>{this.props.version}</span>
					</div>
				</div>
			</div>
		);
	}
}
