import { RGBColor } from "react-color";

export class Color {
	r: number;
	g: number;
	b: number;

	constructor(color: string | RGBColor) {
		let rgb: RGBColor;
		if (typeof color === "string") {
			if (color.substr(0, 1) === "#") {
				rgb = this.hexToRgb(color);
			} else {
				rgb = this.stringToRgb(color);
			}
		} else {
			rgb = color;
		}

		this.r = rgb.r;
		this.g = rgb.g;
		this.b = rgb.b;
	}

	asRGB = () => {
		return {r: this.r, g: this.g, b: this.b};
	}

	asHEX = () => {
		return this.rgbToHex({r: this.r, g: this.g, b: this.b});
	}

	asString = () => {
		return this.rgbToString({r: this.r, g: this.g, b: this.b});
	}

	componentToHex = (c: number) => {
		const hex = c.toString(16);
		return hex.length === 1 ? "0" + hex : hex;
	}

	rgbToHex = ({r, g, b}: {r: number, g: number, b: number}) => {
		return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
	}

	stringToRgb = (colors: string) => {
		let result: RGBColor;
		const redValue = colors.substring(0, 3)
		const greenValue = colors.substring(3, 6)
		const blueValue = colors.substring(6, 9)

		result = {
			r: parseInt(redValue),
			g: parseInt(greenValue),
			b: parseInt(blueValue),
		}
		return result
	}

	stringToHex = (colors: string) => {
		return this.rgbToHex(this.stringToRgb(colors));
	}

	rgbToString = (color: RGBColor) => {
		const redValue = "0".repeat(3 - color.r.toString().length) + color.r.toString()
		const greenValue = "0".repeat(3 - color.g.toString().length) + color.g.toString()
		const blueValue = "0".repeat(3 - color.b.toString().length) + color.b.toString()
		return redValue + greenValue + blueValue;
	}

	hexToRgb = (hex: string) => {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

		if(result === null){
			throw new Error("Couldn't parse hex string.")
		}

		return {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		};
	}

}

export interface Strip {
	name: string
	color: Color;
	intensity: number;
	online: boolean;
	version?: string;
}

export interface City{
	name: string,
	icon: string,
	strips: Strip[];
}

export class Helios extends Array<City>{
	getCities = (): City[] => {
		return this.sort((a, b) => a.name < b.name ? -1 : 1);
	}
}

export class Controller {
	fetching: boolean = false;
	production: boolean = false;
	helios?: Helios;

	constructor(){
		this.fetching = false;
		this.production = process.env.NODE_ENV != "development";
	}

	getBackendUrl = () => {
		return this.production ? "/api" : "http://lysand.re:3011"
	}

	refresh = async () => {
		let helios: [] = await fetch(this.getBackendUrl() + "/get_colors", {method: "POST"})
			.then(response => response.json())

		this.helios = new Helios(...helios).sort((a, b) => a.name > b.name ? -1 : 1);
		this.helios.getCities().forEach(city => {
			city.strips = city.strips.map(strip => {return {
				name: strip.name,
				color: new Color(strip.color),
				intensity: strip.intensity,
				online: new Date().getTime() - new Date((strip as any).lastOnline).getTime() < 10_000,
				version: (strip as any).version
			}});
			city.strips = city.strips.sort((a, b) => a.name < b.name ? -1 : 1)
		})
	}

	getHelios = async (): Promise<Helios> => {
		await this.refresh();

		return this.helios!;
	}

	setColors = async (color: Color, intensity: number | undefined, city: string, strip: string): Promise<void> => {
		await fetch(this.getBackendUrl() + "/set_colors", {
			body: JSON.stringify({color: color.asString(), intensity, city, strip}),
			method: 'POST',
			mode: "cors",
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': "application/json"
			},
		})
	}
}
