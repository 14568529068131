import React from 'react';
import './App.scss';
import beaver from "./Assets/white_beaver.png"
import { CityWidget } from "./Components/CityWidget";
import { City, Controller } from "./Controller";
import { IconName } from "@fortawesome/free-solid-svg-icons";

interface AppState {
    loading: boolean
	cities: City[]
    selectedCity: string | null
}

interface AppProps {}

class App extends React.Component<AppProps, AppState> {

    controller: Controller;

    constructor(props: AppProps) {
        super(props);
        this.state = {loading: true, cities: [], selectedCity: null};

        this.controller = new Controller();
    }

    componentDidMount() {
    	this.controller.getHelios().then(helios => {
    	    this.setState({
                cities: helios.getCities()
            })
        })
    }

    updateState = () => {
        this.controller.getHelios().then(helios => {
            this.setState({
                cities: helios.getCities()
            })
        })
    }

    get selectedCity() {
        return this.state.cities.filter((city, index) => this.state.selectedCity === city.name)
    }

    get citiesList() {
        return this.state.cities;
    }

    render() {
        return (
            <div className={"App"}>
                <div className={"CitiesList"}>
                    {
                        this.citiesList.map((city, index) => {
                            return <CityWidget
                                title={city.name}
                                key={index}
                                nLights={city.strips.length}
                                icon={city.icon as IconName}
                                select={() => this.setState({selectedCity: city.name})}
                                selected={false}
                                strips={city.strips}
                                controller={this.controller}
                                updateState={this.updateState}
                            />
                        })
                    }
                </div>
                {this.state.selectedCity !== null ?
                    <div className={"SelectedCity"}>
                        {
                            this.selectedCity.map((city, index) => {
                                return <CityWidget
                                    title={city.name}
                                    key={index}
                                    nLights={city.strips.length}
                                    icon={city.icon as IconName}
                                    select={() => this.setState({selectedCity: city.name})}
                                    selected={true}
                                    strips={city.strips}
                                    controller={this.controller}
                                    updateState={this.updateState}
                                />
                            })
                        }
                    </div>
                : ""}
                <div className={"footer"}>
                    <img className="logo" src={beaver} alt={"Beaver logo"}/>
                    Helios by Lysandre
                </div>
            </div>
        );
    }
}

export default App;
