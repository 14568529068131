import React from 'react';
import './CityWidget.scss';
import { Color, Controller, Strip } from "../Controller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/free-solid-svg-icons";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { Picker } from "./Picker";
library.add(fas)

export interface ButtonProps {
	primary?: boolean;
	backgroundColor?: string;
	size?: 'small' | 'medium' | 'large';
	label: string;
	onClick?: () => void;
}

export interface CityWidgetProps {
	title: string;
	nLights: number;
	icon: IconName;
	select: () => void;
	selected: boolean
	strips: Strip[];
	controller: Controller;
	updateState: () => void;
}

export class CityWidget extends React.Component<CityWidgetProps> {
	constructor(props: CityWidgetProps) {
		super(props);
	}

	componentDidMount() {
		const widget = document.getElementById(this.props.title + "-widget");

		if (widget) {
			widget.addEventListener("click", this.props.select);
		}
	}

	changeColor = async (color: Color, intensity: number | undefined, stripName: string) => {
		await this.props.controller.setColors(color, intensity, this.props.title, stripName)
		this.props.updateState();
	}

	get sliders() {
		return this.props.strips.map((strip, index) => {
			return <Picker
				color={strip.color}
				intensity={strip.intensity}
				online={strip.online}
				turnedOff={strip.intensity == 0}
				key={this.props.title + index}
				stripIndex={index}
				stripName={strip.name}
				version={strip.version ? strip.version : "Mégalodon"}
				controller={this.props.controller}
				onColorChange={this.changeColor}
				title={this.props.title}
			/>
		})
	}

	render() {
		if (this.props.selected) {
			return (
				<div className={"city-widget"} id={this.props.title + "-widget"}>
					<div className={"header"}>
						{this.props.title}
						<FontAwesomeIcon className={"icon"} icon={this.props.icon}/>
					</div>
					<div className={"strips"}>
						{this.sliders}
					</div>
				</div>
			)
		} else {
			return (
				<div className={"city-widget"} id={this.props.title + "-widget"}>
					<div className={"header"}>
						{this.props.title}
						<FontAwesomeIcon className={"icon"} icon={this.props.icon}/>
					</div>
				</div>
			)
		}
	}
}
